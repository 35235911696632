<!-- =========================================================================================
    File Name: Invoice.vue
    Description: Invoice Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <div id="invoice-page">

        <div class="flex flex-wrap items-center justify-between">
          <vx-input-group class="mb-base mr-3"></vx-input-group>
          <div class="flex items-center">
            <vs-button class="mb-base mr-3" icon-pack="feather" icon="icon icon-file" @click="printInvoice">Print</vs-button>
          </div>
        </div>

        <vx-card id="invoice-container">

            <!-- INVOICE METADATA -->
            <div class="vx-row leading-loose p-base">
                <div class="vx-col w-1/2 mt-base">
                    <img src="https://portal-cdn.rapid.education/images/rapid-edu-logo-wide-extended-264x84.png" alt="rapid-edu-logo">
                </div>
                <div class="vx-col w-1/2 text-right">
                    <h1>{{ invoiceDetails.invoiceStatus | title }}</h1>
                    <div class="invoice__invoice-detail mt-6">
                        <h6>INVOICE NO.</h6>
                        <p>{{ invoiceDetails.invoiceNo }}</p>

                        <h6 class="mt-4">INVOICE DATE</h6>
                        <p>{{ invoiceDetails.invoiceDate | date(true) }}</p>
                    </div>
                </div>
                <div class="vx-col w-1/2 mt-12">
                    <h5>Recipient</h5>
                    <div class="invoice__recipient-info my-4">
                        <p>{{ recipientDetails.fullName }}</p>
                        <p>{{ recipientDetails.addressLine1 }}</p>
                        <p>{{ recipientDetails.addressLine2 }}</p>
                        <p>{{ recipientDetails.zipcode }}</p>
                    </div>
                    <div class="invoice__recipient-contact ">
                        <p class="flex items-center">
                            <feather-icon icon="MailIcon" svgClasses="h-4 w-4"></feather-icon>
                            <span class="ml-2">{{ recipientDetails.mailId }}</span>
                        </p>
                        <p class="flex items-center">
                            <feather-icon icon="PhoneIcon" svgClasses="h-4 w-4"></feather-icon>
                            <span class="ml-2">{{ recipientDetails.mobile }}</span>
                        </p>
                    </div>
                </div>
                <div class="vx-col w-1/2 mt-base text-right mt-12">
                    <h5>{{ companyDetails.name }}</h5>
                    <div class="invoice__company-info my-4">
                        <p>{{ companyDetails.addressLine1 }}</p>
                        <p>{{ companyDetails.addressLine2 }}</p>
                        <p>{{ companyDetails.zipcode }}</p>
                    </div>
                    <div class="invoice__company-contact">
                        <p class="flex items-center justify-end">
                            <feather-icon icon="MailIcon" svgClasses="h-4 w-4"></feather-icon>
                            <span class="ml-2">{{ companyDetails.mailId }}</span>
                        </p>
                        <p class="flex items-center justify-end">
                            <feather-icon icon="PhoneIcon" svgClasses="h-4 w-4"></feather-icon>
                            <span class="ml-2">{{ companyDetails.mobile }}</span>
                        </p>
                    </div>

                </div>
            </div>

            <!-- INVOICE CONTENT -->
            <div class="p-base">
                <!-- INVOICE TASKS TABLE -->
                <vs-table hoverFlat :data="invoiceData.tasks">
                    <!-- HEADER -->
                    <template slot="thead">
                        <vs-th class="pointer-events-none">PRODUCT DESCRIPTION</vs-th>
                        <vs-th class="pointer-events-none">QUANTITY</vs-th>
                        <vs-th class="pointer-events-none">AMOUNT</vs-th>
                    </template>

                    <!-- DATA -->
                    <template slot-scope="{data}">
                        <vs-tr v-for="(tr, index) in data" :key="index">
                            <vs-td :data="data[index].task">{{ data[index].task }}</vs-td>
                            <vs-td :data="data[index].quantity">{{ data[index].quantity }}</vs-td>
                            <vs-td :data="data[index].amount">${{ data[index].amount }} USD</vs-td>
                        </vs-tr>
                    </template>
                </vs-table>

                <!-- INVOICE SUMMARY TABLE -->
                <vs-table no-data-text="" hoverFlat class="w-1/2 ml-auto mt-4">
                    <vs-tr>
                        <vs-th class="pointer-events-none">SUBTOTAL</vs-th>
                        <vs-td>{{ invoiceData.subtotal | currency }} USD</vs-td>
                    </vs-tr>
                    <vs-tr>
                        <vs-th class="pointer-events-none">DISCOUNT</vs-th>
                        <vs-td>{{ invoiceData.discountedAmount | currency }} USD</vs-td>
                    </vs-tr>
                    <vs-tr>
                        <vs-th class="pointer-events-none">TOTAL</vs-th>
                        <vs-td>{{ invoiceData.total | currency}} USD</vs-td>
                    </vs-tr>
                </vs-table>
            </div>
        </vx-card>
    </div>
</template>

<script>

export default{
  data () {
    return {
      mailTo: '',
      companyDetails: {
        name: 'Rapid Education LLC.',
        addressLine1: '46175 Westlake Drive',
        addressLine2: 'Suite 240 & 250',
        zipcode: 'Sterling, VA 20165',
        mailId: 'contact@rapid.education',
        mobile: '+1 703 915 6284'
      },
      recipientDetails: {
        fullName: '',
        addressLine1: '',
        addressLine2: '',
        zipcode: '',
        mailId: '',
        mobile: ''
      },
      invoiceDetails: {
        invoiceStatus: 'pending',
        invoiceNo: '',
        invoiceDate: ''
      },
      invoiceData: {
        tasks: [],
        subtotal: 0,
        discountPercentage: 0,
        discountedAmount: 0,
        total: 0
      }
    }
  },
  computed: {

  },
  methods: {
    printInvoice () {
      window.print()
    },
    loadInvoiceData() {

      this.$http.get('users/@me/customer/address')
        .then(response => {
          if (response.status === 200) {
            const data = response.data.data;

            this.recipientDetails = {
              fullName: this.$store.state.AppActiveUser.full_name,
              addressLine1: data.line1,
              addressLine2: data.line2,
              zipcode: `${data.city} ${data.state}, ${data.postal_code}`,
              mailId: this.$store.state.AppActiveUser.email,
              mobile: this.$store.state.AppActiveUser.phone_number
            }

          }
        })

      this.$http.get(`billing/@me/invoices/${this.$route.params.invoiceId}`)
        .then(response => {

          if (response.status === 200) {

            const data = response.data.data;

            this.invoiceDetails.invoiceStatus = data.status;
            this.invoiceDetails.invoiceNo = data.number;
            this.invoiceDetails.invoiceDate = new Date(data.created * 1000);

            this.invoiceData.tasks = [];
            data.lines.forEach(line => {
              this.invoiceData.tasks.push({
                quantity: line.quantity,
                task: line.description,
                amount: line.amount
              })
            })

            this.invoiceData.total = data.total;
            this.invoiceData.subtotal = data.subtotal;
            this.invoiceData.discountPercentage = 0;
            this.invoiceData.discountedAmount = data.amount_discounted;


          }

        })

    }
  },
  components: {},
  mounted () {
    this.$emit('setAppClasses', 'invoice-page')
    this.loadInvoiceData()
  }
}
</script>

<style lang="scss">
@media print {
  .invoice-page {
    * {
      visibility: hidden;
    }

    #content-area {
      margin: 0 !important;
    }

    .vs-con-table {
      .vs-con-tbody {
        overflow: hidden !important;
      }
    }

    #invoice-container,
    #invoice-container * {
      visibility: visible;
    }
    #invoice-container {
      position: absolute;
      left: 0;
      top: 0;
      box-shadow: none;
    }
  }
}

@page {
  size: auto;
}
</style>
